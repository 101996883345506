import "./App.css";
import "antd/dist/antd.css";

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import Home from "./pages/homepage/home";
import Pricing from "./pages/pricing/pricing";
import BookaDemo from "./pages/bookdemo/bookdemo";
import Contact from "./pages/homepage/components/contact";
import privacyPolicy from "./pages/privcypolicy/privacyPolicy";
import Loading from "./common/loading";

export default class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/book-a-demo" component={BookaDemo} />
          <Route exact path="/contact-us" component={Contact} />
          <Route exact path="/privacy-policy" component={privacyPolicy} />
          <Route exact path="/verify-install" component={Loading} />
        </Switch>
      </Router>
    );
  }
}
