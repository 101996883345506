import React from "react";
import { Row, Col, Modal, Spin } from "antd";
import { toast } from "react-toastify";
import apiCall from "../../../common/apiCall";

export default class PricingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      loading: false
    };
  }
  render() {
    const showModal = () => {
      this.setState({
        isModalVisible: true
      });
    };
    const handleCancel = () => {
      this.setState({
        isModalVisible: false
      });
    };
    const handleName = (e) => {
      this.setState({
        name: e.target.value.trim()
      });
    };
    const handleMail = (e) => {
      this.setState({
        email: e.target.value.trim()
      });
    };
    const handleSkype = (e) => {
      this.setState({
        skype: e.target.value.trim()
      });
    };
    const handleContact = (e) => {
      this.setState({
        contact: e.target.value.trim()
      });
    };
    const handleDesc = (e) => {
      this.setState({
        desc: e.target.value.trim()
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      let param = {
        name: this.state.name,
        email: this.state.email,
        skype: this.state.skype,
        contact: this.state.contact,
        desc: this.state.desc
      };

      let addUser = await apiCall.post("addSccContact", param);
      if (addUser.data.status) {
        toast.success(`${addUser.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        this.setState({
          name: "",
          email: "",
          skype: "",
          contact: "",
          desc: "",
          isModalVisible: false
        });
        this.setState({ loading: false });
      } else {
        toast.error(`${addUser.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        this.setState({ loading: false });
      }
    };
    return (
      <>
        <div className="pricing">
          <div className="top-bg position-absolute"></div>
          <div className="container">
            <Row>
              <Col span={20} xs={24} className="m-auto">
                <div className="page-title text-center">
                  <h2>Build an Automated Mobile Sales Engine for Your Store.</h2>
                  <p>Simple pricing. Zero hidden fees.</p>
                </div>
              </Col>
            </Row>
            <div className="pricing-columns text-center">
              {/* Free Column */}
              <Row>
                {/* Free Column End */}
                <Col xs={24} sm={22} md={12} xl={8} className="m-auto">
                  <div className="princing-details">
                    <h4>ESSENTIAL</h4>
                    <div className="packages_pricing_details princing-details-list position-relative text-center">
                      <h3>
                        <span class="starterPlan-id planPrice ng-binding">$1999 </span>
                      </h3>
                      <span className="permont">(Approx)</span>
                      {/* <span className="sucess-fee">
                        + <br /> 2% Success Fee
                      </span> */}
                      <h4 className="build-text text-uppercase">one time</h4>
                      <h6 className="ideal-businesses">Essential for companies starting up</h6>
                      <p className="revenue"></p>
                      <a className="btn start_free_tr_btn ng-binding" onClick={showModal}>
                        Contact Us
                      </a>
                      <h5 className="everything-infree">You will get - </h5>
                      <ul>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p>Hybrid Android App</p>
                        </li>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p> Flexible Design Layouts</p>
                        </li>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p> FB & Google Ads SDKs</p>
                        </li>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p> Abandoned Cart Campaigns</p>
                        </li>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p> Advance Analytics</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={22} md={12} xl={8} className="m-auto">
                  <div className="princing-details">
                    <h4>ENTERPRISE PLUS</h4>
                    <div className="packages_pricing_details princing-details-list position-relative text-center">
                      <h3>
                        <span class="starterPlan-id planPrice ng-binding">$2499 </span>
                      </h3>
                      <span className="permont">(Approx)</span>
                      {/* <span className="sucess-fee">
                        + <br /> 2% Success Fee
                      </span> */}
                      <h4 className="build-text text-uppercase">one time</h4>
                      <h6 className="ideal-businesses">Essential for Enterprise Businesses</h6>
                      <p className="revenue"></p>
                      <a className="btn start_free_tr_btn ng-binding" onClick={showModal}>
                        Contact Us
                      </a>
                      <h5 className="everything-infree">Everything in Essential Plan + </h5>
                      <ul>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p>Hybrid Android + IOS App</p>
                        </li>{" "}
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p> AI Based Product Recommendations</p>
                        </li>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p>Advanced Push Notification Campaigns</p>
                        </li>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p> Multi-Currency And Multi-Language Support</p>
                        </li>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p> Dedicated Customer Success Manager</p>
                        </li>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p> 24x7 Call and Email Support</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                {/* Free Column End */}
                <Col xs={24} sm={22} md={12} xl={8} className="m-auto">
                  <div className="princing-details">
                    <h4>CUSTOM</h4>
                    <div className="packages_pricing_details princing-details-list position-relative text-center">
                      <h3>
                        <span class="starterPlan-id planPrice ng-binding"> Get in Touch </span>
                      </h3>
                      <span className="permont">One time</span>
                      <h4 className="build-text text-uppercase"></h4>
                      <h6 className="ideal-businesses">Advance Custom Features for High-Volume Enterprises</h6>
                      <p className="revenue">Have a custom request? Reach out to us</p>
                      <a className="btn start_free_tr_btn ng-binding" onClick={showModal}>
                        Contact Us
                      </a>
                      <h5 className="everything-infree">Everything in Enterprise Plus Plan +</h5>
                      <ul>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p>Retention Cohort Analysis</p>
                        </li>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p> Deeplinking Integration</p>
                        </li>
                        <li>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            enable-background="new 0 0 64 64">
                            <g>
                              <path
                                fill="#5C6AC4"
                                d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                            </g>
                          </svg>
                          <p> Omnichannel Solutions</p>
                        </li>{" "}
                      </ul>
                    </div>
                  </div>
                </Col>
                {/* Free Column End */}
              </Row>
            </div>
          </div>
        </div>
        <Modal
          title="BOOK A DEMO"
          visible={this.state.isModalVisible}
          onCancel={handleCancel}
          okButtonProps={{ disabled: true }}
          footer={null}>
          <Spin spinning={this.state.loading}>
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                  Name <span className="requird-filed">*</span>
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="custom-form-input"
                    placeholder="your name"
                    name="c_name"
                    id="c_name"
                    required
                    onChange={handleName}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                  Email Address<span className="requird-filed">*</span>
                </label>
                <div className="col-sm-8">
                  <input
                    type="email"
                    required
                    className="custom-form-input"
                    placeholder="youremail@gmail.com"
                    name="c_email"
                    id="c_email"
                    onChange={handleMail}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                  Skype ID
                  <small className="pl-2 form-text text-muted">(optional)</small>
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="custom-form-input"
                    placeholder="example"
                    name="c_skype"
                    id="c_skype"
                    onChange={handleSkype}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                  Contact Number <span className="requird-filed">*</span>
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    required
                    className="custom-form-input"
                    placeholder="99-000-XXXX-XXX"
                    name="c_con"
                    id="c_con"
                    onChange={handleContact}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="c_name" className="col-sm-4 custom-form-label align-self-start">
                  Add Project Description
                  <small className="pl-2 form-text text-muted">(optional)</small>
                </label>
                <div className="col-sm-8">
                  <textarea
                    className="custom-form-input"
                    rows="4"
                    name="c_requirement"
                    id="c_requirement"
                    style={{ resize: "vertical" }}
                    onChange={handleDesc}></textarea>
                </div>
              </div>
              <div className="form-group row mt-5">
                <div className="col-12 col-sm-auto ml-auto">
                  <input type="submit" className="button btn-frm-submit text-uppercase shadow" value="Submit" />
                </div>
              </div>
            </form>
          </Spin>
        </Modal>
      </>
    );
  }
}
