import React from "react";
import { NavLink } from "react-router-dom";

export default class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="footer-content">
                <p>
                  © Copyright 2021 <a href="http://fleapo.co.in/">Fleapo</a>, All Rights Reserved.
                </p>
                <ul className="footer-link d-flex justify-content-center mb-3">
                  <li>
                    <NavLink
                      onClick={(e) => {
                        window.scrollTo(0, 0);
                      }}
                      exact
                      to="/privacy-policy">
                      Privacy and Policy
                    </NavLink>
                  </li>
                </ul>
                <ul className="media-link d-flex justify-content-center">
                  <li>
                    <a href="https://www.facebook.com/fleapo/" target="_blank">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/fleapo_official" target="_blank">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/fleapo" target="_blank">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
