import React, { Component } from "react";
import { Row, Col } from "antd";

export default class MCommercesucess extends React.Component {
  render() {
    return (
      <section className="M-Ecommerce-sucees">
        <div className="container">
          <Row>
            <Col span={20} xs={24} className="m-auto">
              <div className="page-title text-center">
                <h2>Get Set For M-commerce Success</h2>
                <p>
                  All in one platform to create, market, convert and optimize your mobile app.
                  <br /> Build native mobile apps and engage with your users through notifications powered by robust
                  analytics
                </p>
              </div>
            </Col>
          </Row>
          <div className="ecommerce-thumbnails text-center">
            <Row gutter={[30, 30]}>
              <Col xl={6} xs={24} sm={12} md={8}>
                <div className="thumbnails-wrapper">
                  <img src="/images/mobileban-2.jpg" alt="mobileban-2" />
                  <p>Boost Organic App Installs With Our Free Promotional Tools</p>
                </div>
              </Col>
              <Col xl={6} xs={24} sm={12} md={8}>
                <div className="thumbnails-wrapper">
                  <img src="/images/mobileban-4.jpg" alt="mobileban-2" />
                  <p>Engage Users With Advanced Push Notifications</p>
                </div>
              </Col>
              <Col xl={6} xs={24} sm={12} md={8}>
                <div className="thumbnails-wrapper">
                  <img src="/images/mobileban-3.jpg" alt="mobileban-2" />
                  <p>Optimized Layouts To Enhance User Shopping Experience</p>
                </div>
              </Col>
              <Col xl={6} xs={24} sm={12} md={8}>
                <div className="thumbnails-wrapper">
                  <img src="/images/mobileban-1.jpg" alt="mobileban-2" />
                  <p>Apple & Andriod Pay For Faster Checkouts</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    );
  }
}
