import React from "react";
import { Row, Col } from "antd";

export default class MobileApppExperiencss extends React.Component {
  render() {
    return (
      <section className="mobile-app-exprience">
        <div className="container">
          <Row>
            <Col span={24}>
              <div className="page-title text-center">
                <h2>Everything You Need For A Seamless Mobile App Experience</h2>
              </div>
            </Col>
          </Row>
          <div className="thumbnails-apps">
            <Row gutter={[30, 30]}>
              <Col xl={8} xs={24} sm={12} md={8}>
                <div className="thumbnails-wrapper">
                  <div
                    className="circle rounded-circle"
                    style={{ backgroundImage: `url("/images/no-codingrequird.png")` }}></div>
                  <div className="content">
                    <h3>No coding required</h3>
                    <p>You don't need even a single line of code to build your app on our platform.</p>
                  </div>
                </div>
              </Col>
              <Col xl={8} xs={24} sm={12} md={8}>
                <div className="thumbnails-wrapper">
                  <div
                    className="circle rounded-circle"
                    style={{ backgroundImage: `url("/images/no-codingrequird.png")` }}></div>
                  <div className="content">
                    <h3>Fully Native Apps</h3>
                    <p>Native apps for iOS and Android to provide a smooth and superior user experience.</p>
                  </div>
                </div>
              </Col>
              <Col xl={8} xs={24} sm={12} md={8}>
                <div className="thumbnails-wrapper">
                  <div
                    className="circle rounded-circle"
                    style={{ backgroundImage: `url("/images/no-codingrequird.png")` }}></div>
                  <div className="content">
                    <h3>True DIY Experience</h3>
                    <p>Our Drag n Drop mobile app builder to let you build your app, the way you want.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="thumbnails-apps second-row">
            <Row gutter={[30, 30]}>
              <Col xl={8} xs={24} sm={12} md={8}>
                <div className="thumbnails-wrapper">
                  <div
                    className="circle rounded-circle"
                    style={{ backgroundImage: `url("/images/no-codingrequird.png")` }}></div>
                  <div className="content">
                    <h3>Awe-inspiring Layouts</h3>
                    <p>You don't need even a single line of code to build your app on our platform.</p>
                  </div>
                </div>
              </Col>
              <Col xl={8} xs={24} sm={12} md={8}>
                <div className="thumbnails-wrapper">
                  <div
                    className="circle rounded-circle"
                    style={{ backgroundImage: `url("/images/no-codingrequird.png")` }}></div>
                  <div className="content">
                    <h3>Supercharged Dashboards</h3>
                    <p>Native apps for iOS and Android to provide a smooth and superior user experience.</p>
                  </div>
                </div>
              </Col>
              <Col xl={8} xs={24} sm={12} md={8}>
                <div className="thumbnails-wrapper">
                  <div
                    className="circle rounded-circle"
                    style={{ backgroundImage: `url("/images/no-codingrequird.png")` }}></div>
                  <div className="content">
                    <h3>24x7 Support</h3>
                    <p>Our Drag n Drop mobile app builder to let you build your app, the way you want.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    );
  }
}
