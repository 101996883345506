import axios from "axios";
require("dotenv").config();
let api_url =
  process.env.NODE_ENV === "production"
    ? "https://webadmin.scentcraft.com/api/gifting/"
    : "http://localhost:7979/api/gifting/";

class ApiCall {
  post = (url, data) =>
    new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      var headers = {
        Authorization: "Bearer " + token
      };

      axios
        .post(api_url + url, data, token ? { headers } : undefined)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });

  get = (url) =>
    new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      var headers = {
        Authorization: "Bearer " + token
      };

      axios
        .get(api_url + url, { headers })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
}

export default new ApiCall();
