import React, { Component } from "react";
import Navbar from "../../common/navbar";
import Footer from "../../common/footer";
import { Row, Col, Modal, Spin } from "antd";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import apiCall from "../../common/apiCall";

export default class bookdemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      loading: false
    };
  }
  render() {
    var { name } = this.state;
    const showModal = () => {
      this.setState({
        isModalVisible: true
      });
    };
    const handleCancel = () => {
      this.setState({
        isModalVisible: false
      });
    };
    const handleMail = (e) => {
      this.setState({
        email: e.target.value.trim()
      });
    };
    const handleSkype = (e) => {
      this.setState({
        skype: e.target.value.trim()
      });
    };
    const handleContact = (e) => {
      this.setState({
        contact: e.target.value.trim()
      });
    };
    const handleDesc = (e) => {
      this.setState({
        desc: e.target.value.trim()
      });
    };
    const handleSubmit = async (e) => {
      e.preventDefault();
      var { name, email, skype } = this.state;
      this.setState({ loading: true });
      let param = {
        name: name,
        email: email,
        skype: skype,
        contact: this.state.contact,
        desc: this.state.desc
      };

      let addUser = await apiCall.post("addSccContact", param);
      if (addUser.data.status) {
        toast.success(`${addUser.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        this.setState({
          name: "",
          email: "",
          skype: "",
          contact: "",
          desc: "",
          isModalVisible: false
        });
        this.setState({ loading: false });
      } else {
        toast.error(`${addUser.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        this.setState({ loading: false });
      }
    };
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Navbar />
        <section className="book-a-demo">
          <div className="container">
            <Row>
              <Col span={24}>
                <div className="page-title text-center">
                  <h2>Increase Sales & Profits With WebToApp</h2>
                  <p>
                    Establish an ongoing relationship with your <br /> customers via push notifications
                  </p>
                </div>
              </Col>
            </Row>
            <div className="thumbnail-wrapper">
              <Row className="h-100 align-item-center">
                <Col className="m-auto" md={18} xl={12}>
                  <div className="main-img">
                    <img src="/images/bookademo.png" className="img-fluid" alt="bookademo" />
                  </div>
                </Col>
                <Col className="m-auto" md={18} xl={12}>
                  <div className="content-wrapper">
                    <ul>
                      <li>
                        <i class="fa fa-check pr-2 pa-theme-color "></i>Increase moblie conversions rate
                      </li>
                      <li>
                        <i class="fa fa-check pr-2 pa-theme-color "></i>Increase average order value
                      </li>
                      <li>
                        <i class="fa fa-check pr-2 pa-theme-color "></i>Increase purchases per customers per year
                      </li>
                      <li>
                        <i class="fa fa-check pr-2 pa-theme-color "></i>Acquire new customers who will buy multiple
                        times per year
                      </li>
                    </ul>
                    <div className="app-wrapper">
                      <h4>
                        <img
                          className="pa-phone-img"
                          src="https://d3myyafggcycom.cloudfront.net/STAGING/c3dbd481-e669-11e9-bbc6-06fa44be0788/5f44caa332532.png"
                          alt="mobile"
                        />
                        Only pay for in app sales | Free trial period
                      </h4>
                    </div>
                    <div className="button-wrapper">
                      <button
                        onClick={showModal}
                        class="button-with-arrow hero_banner_btn mobile_view_text_align"
                        id="largenc1btn">
                        <label>BOOK A DEMO</label>
                        <span class="lnr lnr-arrow-right"></span>
                      </button>

                      <img
                        class="img-max certified-img"
                        src="https://d3myyafggcycom.cloudfront.net/STAGING/949dbedd-bda2-11e7-9b5e-06a0141b1d47/5f5f2a20808ff.png"
                        at="certified"></img>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Modal
            title="BOOK A DEMO"
            visible={this.state.isModalVisible}
            onCancel={handleCancel}
            okButtonProps={{ disabled: true }}
            footer={null}>
            <Spin spinning={this.state.loading}>
              <form onSubmit={handleSubmit}>
                <div className="form-group row">
                  <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                    Name <span className="requird-filed">*</span>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="custom-form-input"
                      placeholder="your name"
                      name="c_name"
                      id="c_name"
                      value={name}
                      required
                      onChange={(e) => {
                        this.setState({ name: e.target.value.trim() });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                    Email Address<span className="requird-filed">*</span>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="email"
                      required
                      className="custom-form-input"
                      placeholder="youremail@gmail.com"
                      name="c_email"
                      id="c_email"
                      onChange={handleMail}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                    Skype ID
                    <small className="pl-2 form-text text-muted">(optional)</small>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="custom-form-input"
                      placeholder="example"
                      name="c_skype"
                      id="c_skype"
                      onChange={handleSkype}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                    Contact Number <span className="requird-filed">*</span>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      required
                      className="custom-form-input"
                      placeholder="99-000-XXXX-XXX"
                      name="c_con"
                      id="c_con"
                      onChange={handleContact}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="c_name" className="col-sm-4 custom-form-label align-self-start">
                    Add Project Description
                    <small className="pl-2 form-text text-muted">(optional)</small>
                  </label>
                  <div className="col-sm-8">
                    <textarea
                      className="custom-form-input"
                      rows="4"
                      name="c_requirement"
                      id="c_requirement"
                      style={{ resize: "vertical" }}
                      onChange={handleDesc}></textarea>
                  </div>
                </div>
                <div className="form-group row mt-5">
                  <div className="col-12 col-sm-auto ml-auto">
                    <input type="submit" className="button btn-frm-submit text-uppercase shadow" value="Submit" />
                  </div>
                </div>
              </form>
            </Spin>
          </Modal>
        </section>
        <Footer />
      </>
    );
  }
}
