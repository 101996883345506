import React from "react";
import { Row, Col } from "antd";
export default class Plancamparison extends React.Component {
  componentDidMount() {
    let accordion = document.querySelector(".accordion-list");
    let items = accordion.querySelectorAll("li");
    let questions = accordion.querySelectorAll(".accordion-title");

    questions.forEach((question) => question.addEventListener("click", toggleAccordion));

    function toggleAccordion() {
      let thisItem = this.parentNode;
      items.forEach((item) => {
        if (thisItem === item) {
          thisItem.classList.toggle("open");
          return;
        }
        item.classList.remove("open");
      });
    }
  }
  render() {
    const svgico = (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64">
        <g>
          <path
            fill="#5C6AC4"
            d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
        </g>
      </svg>
    );

    return (
      <>
        <section className="comparison-faq" id="faq">
          <div className="container">
            <Row>
              <Col span={24}>
                <div className="page-title text-center">
                  <h2>View Detailed Plan Comparison</h2>
                </div>
              </Col>
            </Row>
            <div className="row">
              <div className="col-12">
                <ul className="accordion-list">
                  <li>
                    <div className="accordion-title d-flex justify-content-between">
                      <h4>BUILD </h4>
                      <figure>
                        <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                        </svg>
                      </figure>
                    </div>
                    <p>
                      <div className="table-wrapper-showing table-responsive">
                        <table className="table">
                          <thead>
                            <tr className="main-title">
                              <td></td>
                              <td>FREE</td>
                              <td>ESSENTIAL</td>
                              <td>ENTERPRISE PLUS</td>
                              <td>CUSTOM</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th colSpan="5" className="table-title">
                                Platform
                              </th>
                            </tr>
                            <tr>
                              <td>iOS and Android App</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                          </tbody>
                        </table>
                        {/* Mobile App Features Start Table */}
                        <table className="table spacing-table">
                          <tr>
                            <th colSpan="5" className="table-title">
                              Mobile App Features
                            </th>
                          </tr>
                          <tr>
                            <td>Wishlist</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>Real-Time Store Sync</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>Address Autocomplete</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>Push Notifications with Images</td>
                            <td></td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>Single Page Checkout</td>
                            <td></td>
                            <td></td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>Schedule Push Notifications</td>
                            <td></td>
                            <td></td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                        </table>
                        {/* App Design Start Table */}
                        <table className="table spacing-table">
                          <tr>
                            <th colSpan="5" className="table-title">
                              App Design
                            </th>
                          </tr>
                          <tr>
                            <td>Flexible Layouts</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>Fit/Fill</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>Customizable Font</td>
                            <td></td>
                            <td></td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>Editable CTAs</td>
                            <td></td>
                            <td></td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                        </table>
                        {/* App Layout Start Table */}
                        <table className="table spacing-table">
                          <tr>
                            <th colSpan="5" className="table-title">
                              App Layout
                            </th>
                          </tr>
                          <tr>
                            <td>Hero Banners</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>GIF Banners</td>
                            <td></td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>Flash Sale Banners</td>
                            <td></td>
                            <td></td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                          <tr>
                            <td>Product Recommendation Banners</td>
                            <td></td>
                            <td></td>
                            <td>{svgico}</td>
                            <td>{svgico}</td>
                          </tr>
                        </table>
                      </div>
                    </p>
                  </li>
                  {/* Market Table Start*/}
                  <li>
                    <div className="accordion-title d-flex justify-content-between">
                      <h4>MARKET </h4>
                      <figure>
                        <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                        </svg>
                      </figure>
                    </div>
                    <p>
                      <div className="table-wrapper-showing">
                        {/* App Marketing Tools Table */}
                        <table className="table">
                          <thead>
                            <tr className="main-title">
                              <td></td>
                              <td>FREE</td>
                              <td>ESSENTIAL</td>
                              <td>ENTERPRISE PLUS</td>
                              <td>CUSTOM</td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="table-title">
                                App Marketing Tools
                              </th>
                            </tr>
                            <tr>
                              <td>Instalify</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>QR Code</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Google Ads SDK</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Facebook Ads SDK</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Advanced Instalify</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Deeplinking</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>FB Remarketing Ads with Deeplinking</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </p>
                  </li>
                  {/* Engage Table Start */}
                  <li>
                    <div className="accordion-title d-flex justify-content-between">
                      <h4>ENGAGE </h4>
                      <figure>
                        <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                        </svg>
                      </figure>
                    </div>
                    <p>
                      <div className="table-wrapper-showing">
                        {/* Notification Campaigns Table */}
                        <table className="table">
                          <thead>
                            <tr className="main-title">
                              <td></td>
                              <td>FREE</td>
                              <td>ESSENTIAL</td>
                              <td>ENTERPRISE PLUS</td>
                              <td>CUSTOM</td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="table-title">
                                Notification Campaigns
                              </th>
                            </tr>
                            <tr>
                              <td>Push Notifications</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Welcome Offer Campaigns</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Push Notification With Images</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Abandoned Cart Campaigns</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Inactive Users Campaigns</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Review Campaigns</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Abandoned Wishlist Campaigns</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Abandoned Browse Campaigns</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </p>
                  </li>
                  {/* ANALYZE Table Start */}
                  <li>
                    <div className="accordion-title d-flex justify-content-between">
                      <h4>ANALYZE </h4>
                      <figure>
                        <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                        </svg>
                      </figure>
                    </div>
                    <p>
                      <div className="table-wrapper-showing">
                        {/* Notification Campaigns Table */}
                        <table className="table">
                          <thead>
                            <tr className="main-title">
                              <td></td>
                              <td>FREE</td>
                              <td>ESSENTIAL</td>
                              <td>ENTERPRISE PLUS</td>
                              <td>CUSTOM</td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="table-title">
                                Analytics
                              </th>
                            </tr>
                            <tr>
                              <td>User Sessions</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>App Revenues</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Top Searches</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Top Viewed Products</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>User Segmentations</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Conversion Funnels</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Uninstall Tracking</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>DAUs/MAUs</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Firebase</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Retention Cohort Analysis</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </p>
                  </li>
                  {/* INTEGRATIONS Table Start */}
                  <li>
                    <div className="accordion-title d-flex justify-content-between">
                      <h4>INTEGRATIONS </h4>
                      <figure>
                        <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                        </svg>
                      </figure>
                    </div>
                    <p>
                      <div className="table-wrapper-showing">
                        {/* Notification Campaigns Table */}
                        <table className="table">
                          <thead>
                            <tr className="main-title">
                              <td></td>
                              <td>FREE</td>
                              <td>ESSENTIAL</td>
                              <td>ENTERPRISE PLUS</td>
                              <td>CUSTOM</td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="table-title">
                                Integrations
                              </th>
                            </tr>
                            <tr>
                              <td>Shopify BUY SDK 3</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Instant Search +</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Top Searches</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Facebook</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Instagram</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Youtube</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Twitter</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Blogs</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Apple Pay</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Facebook Widget</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>WhatsApp Widget</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Yotpo</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Judge</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Loyalty Lion</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Variant Options Swatch King</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Variant Options Image Automator</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Smile</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Swell Rewards</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Recharge</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Intercom</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Klaviyo</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Local Delivery</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Searchanise</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>PushOwl</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Langify</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>LimeSpot</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Bold Product Options</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Clevertap</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Branch</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Firebase</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Multi-store Integration</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </p>
                  </li>
                  {/* Support Table Start */}
                  <li>
                    <div className="accordion-title d-flex justify-content-between">
                      <h4>SUPPORT</h4>
                      <figure>
                        <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                        </svg>
                      </figure>
                    </div>
                    <p>
                      <div className="table-wrapper-showing">
                        {/* Support Campaigns Table */}
                        <table className="table">
                          <thead>
                            <tr className="main-title">
                              <td></td>
                              <td>FREE</td>
                              <td>ESSENTIAL</td>
                              <td>ENTERPRISE PLUS</td>
                              <td>CUSTOM</td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="table-title">
                                Support
                              </th>
                            </tr>
                            <tr>
                              <td>Email Support</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Live Chat Support</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Customer Success Manager</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </p>
                  </li>
                  {/* Advanced Features Table Start */}
                  <li>
                    <div className="accordion-title d-flex justify-content-between">
                      <h4>Advanced Features</h4>
                      <figure>
                        <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                        </svg>
                      </figure>
                    </div>
                    <p>
                      <div className="table-wrapper-showing">
                        {/* Support Campaigns Table */}
                        <table className="table">
                          <thead>
                            <tr className="main-title">
                              <td></td>
                              <td>FREE</td>
                              <td>ESSENTIAL</td>
                              <td>ENTERPRISE PLUS</td>
                              <td>CUSTOM</td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="table-title">
                                Advanced Features
                              </th>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Local Delivery</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Social Logins (Facebook)</td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Smart Filters</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Advanced Search</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Multi Currency</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Multi Language</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Pre-Order</td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>AI Based Product Recommendations</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </p>
                  </li>
                  {/* OMNICHANNEL Table Start */}
                  <li>
                    <div className="accordion-title d-flex justify-content-between">
                      <h4>OMNICHANNEL</h4>
                      <figure>
                        <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                        </svg>
                      </figure>
                    </div>
                    <p>
                      <div className="table-wrapper-showing">
                        {/* Support Campaigns Table */}
                        <table className="table">
                          <thead>
                            <tr className="main-title">
                              <td></td>
                              <td>FREE</td>
                              <td>ESSENTIAL</td>
                              <td>ENTERPRISE PLUS</td>
                              <td>CUSTOM</td>
                            </tr>
                            <tr>
                              <td>BOPIS</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>BORIS</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                            </tr>
                            <tr>
                              <td>Endless Aisle</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>{svgico}</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="important-notice">
            <div className="container">
              <Row>
                <Col span={24}>
                  <div className="inner-wrapper">
                    <p>
                      <strong>Important Note:</strong> <br />
                      1. All Customers need to create their own Developer Accounts for Google and Apple <br />
                      &nbsp; - Apple Charges USD 99 per year for an iOS Developer Account
                      <br />
                      &nbsp; - Google charges USD 25 as a one time lifetime cost for a Google Developer
                      <br />
                      2. Apps uploaded to Google Play Stores and Apple App Stores are governed by their respective app
                      store policies.
                      <br />
                      3. All payments will be billed in US dollars.
                      <br />
                      4. All custom development requests will be charged additional.
                      <br />
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </>
    );
  }
}
