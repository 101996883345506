import React from "react";
import { Row, Col } from "antd";

export default class LatestBrand extends React.Component {
  render() {
    return (
      <>
        <div className="latest-brands">
          <div className="container">
            <Row>
              <Col span={24}>
                <div className="page-title text-center">
                  <h2>Powering Mobile Apps For The World’s Fastest Growing Brands</h2>
                </div>
              </Col>
            </Row>
            <div className="brands">
              <Row>
                <Col span={24}>
                  <div className="flex-wrapper justify-content-between d-flex">
                    <img
                      src="https://cdn.shopify.com/s/files/1/0424/4337/4744/files/Fresh_Aisle_02_150x.png?v=1626333097"
                      alt=""
                    />
                    <img
                      src="https://cdn.shopify.com/s/files/1/0526/7079/1850/files/Risorsa_3_3x_cda43577-531c-49d6-b3d0-35b11c5cbffe_720x.png?v=1611993345"
                      alt=""
                    />
                    <img src="https://jeweltoday.in/assets/img/logo.png" alt="" />
                    <img
                      src="https://dw2sytisfgmrr.cloudfront.net/tagmango_typeface-%281%29.svg-1622023999423.svg"
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}
