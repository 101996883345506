import React from "react";
import "../loading.css";
import apiCall from "../common/apiCall";

export default class Load extends React.Component {
  async sendQuery() {
    let code = new URLSearchParams(window.location.search).get("code");
    let shop = new URLSearchParams(window.location.search).get("shop");
    let hmac = new URLSearchParams(window.location.search).get("hmac");
    let param = {
      code,
      shop,
      searchLocation: window.location.search
    };
    if (hmac && code && shop) {
      let req = await apiCall.post("generateToken", param);
      if (req.data.status) {
        alert("Congratulations! Our App has been installed!");
        window.location.href = "https://" + shop + "/admin/apps/webtoapp";
      } else {
        alert("Some error occured! Please try installing our app again!");
        window.location.href = "https://webtoapp.fleapo.com";
      }
    } else {
      alert("Some error occured! Please try installing our app again!");
      window.location.href = "https://webtoapp.fleapo.com";
    }
  }

  componentDidMount() {
    this.sendQuery();
  }

  render() {
    return (
      <div
        className="cover"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          overflow: "hidden",
          width: "100%"
        }}>
        <div className="boxes">
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}
