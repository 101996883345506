import React from "react";
import { Row, Col } from "antd";

export default class Faq extends React.Component {
  componentDidMount() {
    let accordion = document.querySelector(".accordion-list");
    let items = accordion.querySelectorAll("li");
    let questions = accordion.querySelectorAll(".accordion-title");

    questions.forEach((question) => question.addEventListener("click", toggleAccordion));

    function toggleAccordion() {
      let thisItem = this.parentNode;
      items.forEach((item) => {
        if (thisItem === item) {
          thisItem.classList.toggle("open");
          return;
        }
        item.classList.remove("open");
      });
    }
  }
  render() {
    return (
      <section className="faq" id="faq">
        <div className="container">
          <Row>
            <Col span={24}>
              <div className="page-title text-center">
                <h2>Frequently Asked Questions</h2>
              </div>
            </Col>
          </Row>
          <div className="row">
            <div className="col-12">
              <ul className="accordion-list">
                <li>
                  <div className="accordion-title d-flex justify-content-between">
                    <h4>Can I preview my app before I publish it? </h4>
                    <figure>
                      <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                      </svg>
                    </figure>
                  </div>
                  <p>
                    We offer a free demo so you can get an idea of how your app would look and check out its
                    functionality before you publish on the stores.
                  </p>
                </li>
                <li>
                  <div className="accordion-title d-flex justify-content-between">
                    <h4>How do I check my app preview? </h4>
                    <figure>
                      <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                      </svg>
                    </figure>
                  </div>
                  <p>
                    Navigate to the Shopify App Store and search for WebToApp. Click on ‘Get’ and add WebToApp to your
                    web store. All your products will automatically get added to your app. We will then reach out to you
                    for your app preview.
                  </p>
                </li>
                <li>
                  <div className="accordion-title d-flex justify-content-between">
                    <h4>Will there be any additional costs?</h4>
                    <figure>
                      <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                      </svg>
                    </figure>
                  </div>
                  <p>Our pricing plans are all inclusive. There is no hidden or additional cost involved.</p>
                </li>
                <li>
                  <div className="accordion-title d-flex justify-content-between">
                    <h4>Can I customize my app before publishing?</h4>
                    <figure>
                      <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                      </svg>
                    </figure>
                  </div>
                  <p>
                    Yes, you can customize your app before publishing it. You can make changes to the brand logo, splash
                    screen, header color of the app, main banner, promo images and information page, etc.
                  </p>
                </li>
                <li>
                  <div className="accordion-title d-flex justify-content-between">
                    <h4>Can I change my plan later?</h4>
                    <figure>
                      <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                      </svg>
                    </figure>
                  </div>
                  <p>
                    You can upgrade or downgrade your plan at any time and you will be charged pro-data through the end
                    of your billing term. You can downgrade at any time, but changes will not take effect until the
                    start of the next term.
                  </p>
                </li>
                <li>
                  <div className="accordion-title d-flex justify-content-between">
                    <h4>Can we switch back to the old Checkout experience?</h4>
                    <figure>
                      <svg width="10" height="7" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 .799l4 4 4-4" stroke="#12b6b0" strokeWidth="2" fill="none" fillRule="evenodd" />
                      </svg>
                    </figure>
                  </div>
                  <p>
                    Yes, we can absolutely turn off our custom made checkout experience and switch back to the Shopify
                    Web Checkout when required.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
