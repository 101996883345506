import React from "react";
import Navbar from "../../common/navbar";
import GetInTouch from "./components/getintouch";
import LatestBrand from "./components/brands";
import Plancamparison from "./components/plancamparison";
import PricingTable from "./components/pricingtable";
import Footer from "../../common/footer";
import { ToastContainer } from "react-toastify";
export default class Pricing extends React.Component {
  render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Navbar />
        <PricingTable />
        {/* <GetInTouch /> */}
        <Plancamparison />
        <LatestBrand />
        <Footer />
      </>
    );
  }
}
