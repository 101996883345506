import React from "react";
import { Row, Col } from "antd";

export default class GetInTouch extends React.Component {
  render() {
    return (
      <>
        <div className="getin-touch">
          <div className="top-bg position-absolute"></div>
          <div className="container">
            <div className="outer-wrapper">
              <Row>
                <Col xs={24} md={8}>
                  <div className="columns-wrap text-center getintouch-columnleft">
                    <h2>CUSTOM</h2>
                    <div class="packages_pricing_details">
                      <h3>Get in Touch</h3>
                      <a className="btn start_free_tr_btn ng-binding">Contact Us</a>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div className="middle-column text-center">
                    <h6>Advance Custom Features for High-Volume Enterprises</h6>
                    <span>$100Mn+ in Revenue</span>
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <div className="right-column princing-details-list">
                    <h4>Everything in Enterprise Plus Plan +</h4>
                    <ul>
                      <li>
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                          enable-background="new 0 0 64 64">
                          <g>
                            <path
                              fill="#5C6AC4"
                              d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                          </g>
                        </svg>
                        <p> Retention Cohort Analysis</p>
                      </li>
                      <li>
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                          enable-background="new 0 0 64 64">
                          <g>
                            <path
                              fill="#5C6AC4"
                              d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                          </g>
                        </svg>
                        <p> Deeplinking Integration</p>
                      </li>
                      <li>
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                          enable-background="new 0 0 64 64">
                          <g>
                            <path
                              fill="#5C6AC4"
                              d="m16.074,55.049c0.369,0.534 0.975,0.838 1.615,0.838 0.133,0 0.269-0.049 0.404-0.076 0.098,0.016 0.193,0.064 0.292,0.064 0.575,0 1.134-0.249 1.526-0.681l43.514-43.521c0.785-0.784 0.785-2.056 0-2.841-0.784-0.784-2.056-0.784-2.84,0l-42.52,42.526-14.34-14.337c-0.784-0.785-2.056-0.785-2.84,0-0.785,0.784-0.785,2.056 0,2.841l15.189,15.187z"></path>
                          </g>
                        </svg>
                        <p> Omnichannel Solutions</p>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }
}
