import React from "react";
import { Row, Col, Spin } from "antd";
require("dotenv").config();

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: "",
      loading: false
    };
  }
  componentDidMount = async () => {};

  render() {
    const onInputChange = (e) => {
      this.setState({
        domain: e.target.value.trim()
      });
    };
    const handleClick = async (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      let domain = this.state.domain.replace(".myshopify.com", "");
      let api_key = process.env.REACT_APP_PARTNERAPP_APIKEY;
      let redirect_uri = encodeURI(process.env.REACT_APP_PARTNERAPP_REDIRECTURL);
      let scopes = process.env.REACT_APP_PARTNERAPP_SCOPES;

      const install_url = `https://${domain}.myshopify.com/admin/oauth/authorize?client_id=${api_key}&scope=${scopes}&redirect_uri=${redirect_uri}`;
      window.location.href = install_url;
    };
    return (
      <Spin spinning={this.state.loading}>
        <header className="d-flex align-items-center" style={{ backgroundImage: `url("/images/hero-bg.png")` }}>
          <div className="container">
            <Row className="h-100 align-items-center">
              <Col xl={14} xs={24} md={20} className="m-auto">
                <div className="header-content">
                  <h1>Web To App</h1>
                  <h5>
                    Want to supercharge your business?
                    <br />
                    We help you convert your website to a custom made mobile app.
                  </h5>
                  <div className="get-in-touch w-100">
                    <h6>Get started. Install our app NOW!</h6>
                    <div className="input-wrap position-relative mt-3">
                      <form onSubmit={handleClick}>
                        <input required type="text" placeholder="my-shopify-domain" onChange={onInputChange} />
                        <button type="submit" name="submit" className="button-input position-absolute">
                          Install on <i className="fab fa-shopify"></i>
                          <i>
                            <b>Shopify</b>
                          </i>
                        </button>
                      </form>
                    </div>
                    <div className="shopify-partner mt-3">
                      <img src="/images/shopify-partnerlogo.png" alt="Shopify Partner" />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={10} xs={24} md={12} className="m-auto">
                <div className="header-animate-img">
                  <img src="/images/gif2.gif" className="img-fluid" alt="img" />
                </div>
              </Col>
            </Row>
          </div>
        </header>
      </Spin>
    );
  }
}
