import React from "react";
import { Row, Col, Spin } from "antd";
import { toast } from "react-toastify";
import apiCall from "../../../common/apiCall";

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      skype: "",
      contact: "",
      desc: "",
      loading: false
    };
  }

  render() {
    const handleName = (e) => {
      this.setState({
        name: e.target.value.trim()
      });
    };
    const handleMail = (e) => {
      this.setState({
        email: e.target.value.trim()
      });
    };
    const handleSkype = (e) => {
      this.setState({
        skype: e.target.value.trim()
      });
    };
    const handleContact = (e) => {
      this.setState({
        contact: e.target.value.trim()
      });
    };
    const handleDesc = (e) => {
      this.setState({
        desc: e.target.value.trim()
      });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      let param = {
        name: this.state.name,
        email: this.state.email,
        skype: this.state.skype,
        contact: this.state.contact,
        desc: this.state.desc
      };

      let addUser = await apiCall.post("addSccContact", param);
      if (addUser.data.status) {
        toast.success(`${addUser.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        this.setState({
          name: "",
          email: "",
          skype: "",
          contact: "",
          desc: ""
        });
        this.setState({ loading: false });
      } else {
        toast.error(`${addUser.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        this.setState({ loading: false });
      }
    };
    return (
      <section className="contact-form" id="contact-form">
        <div className="container">
          <Row>
            <Col span={24}>
              <div className="page-title text-center">
                <h2>Contact Us</h2>
              </div>
            </Col>
          </Row>
          <div className="row h-100 align-items-center">
            <div className="col-lg-7 m-auto">
              <div className="form-wrapper">
                <Spin spinning={this.state.loading}>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                        Name <span className="requird-filed">*</span>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="custom-form-input"
                          placeholder="your name"
                          name="c_name"
                          id="c_name"
                          required
                          onChange={handleName}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                        Email Address<span className="requird-filed">*</span>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="email"
                          required
                          className="custom-form-input"
                          placeholder="youremail@gmail.com"
                          name="c_email"
                          id="c_email"
                          onChange={handleMail}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                        Skype ID
                        <small className="pl-2 form-text text-muted">(optional)</small>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="custom-form-input"
                          placeholder="example"
                          name="c_skype"
                          id="c_skype"
                          onChange={handleSkype}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="c_name" className="col-sm-4 custom-form-label">
                        Contact Number <span className="requird-filed">*</span>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          required
                          className="custom-form-input"
                          placeholder="99-000-XXXX-XXX"
                          name="c_con"
                          id="c_con"
                          onChange={handleContact}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="c_name" className="col-sm-4 custom-form-label align-self-start">
                        Add Project Description
                        <small className="pl-2 form-text text-muted">(optional)</small>
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="custom-form-input"
                          rows="4"
                          name="c_requirement"
                          id="c_requirement"
                          style={{ resize: "vertical" }}
                          onChange={handleDesc}></textarea>
                      </div>
                    </div>
                    <div className="form-group row mt-5">
                      <div className="col-12 col-sm-auto ml-auto">
                        <input type="submit" className="button btn-frm-submit text-uppercase shadow" value="Submit" />
                      </div>
                    </div>
                  </form>
                </Spin>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="form-content">
                <h3>Get a Quote Now!</h3>
                <p>
                  Want your checkout to be re-built? Fill out the form and our team will get back to you to the earliest
                  with a quote.
                </p>
                <p>
                  Diamond Prestige, Office #410 <br /> 41A, AJC Bose Road,
                  <br /> Kolkata - 700016,
                  <br /> West Bengal, India.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
