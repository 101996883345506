import React, { useState } from "react";
import $ from "jquery";
import { Row, Col } from "antd";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  const [navwrapper, setNavbar] = useState(false);
  const [navbaropen, setNavbarOpen] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 5) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const handleToggle = () => {
    setNavbarOpen(!navbaropen);
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <div className={navwrapper ? "navwrapper floatingNav" : "navwrapper"}>
      <div className="container">
        <Row>
          <Col span={24}>
            <nav className="d-flex justify-content-between align-items-center">
              <div className="logo">
                <NavLink exact to="/">
                  <img src="/images/logo.png" alt="Logo" />
                </NavLink>
              </div>
              <div onClick={handleToggle} className={navbaropen ? "hamburger toggle" : "hamburger"}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
              </div>
              <ul className={`nav-links ${navbaropen ? " open" : ""}`}>
                <li>
                  <NavLink exact to="/">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/pricing">
                    Pricing
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/book-a-demo">
                    Book a demo
                  </NavLink>
                </li>
                <li>
                  <div className="contact-button">
                    <a href="/#contact-form"> Contact Us </a>
                  </div>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </div>
    </div>
  );
}
