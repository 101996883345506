import React, { Component } from "react";
import Navbar from "../../common/navbar";
import Header from "./components/header";
import MCommercesucess from "./components/m-commercesuccess";
import MobileApppExperiencss from "./components/mobileapppexperience";
import Faq from "./components/faq";
import Contact from "./components/contact";
import Footer from "../../common/footer";
import { ToastContainer } from "react-toastify";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideBanner: false
    };
  }
  componentDidMount = () => {
    if (
      window.location.search.includes("hmac=") &&
      window.location.search.includes("session=") &&
      window.location.search.includes("host=")
    ) {
      this.setState({ hideBanner: true });
    }
  };
  render() {
    var { hideBanner } = this.state;
    return (
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Navbar />
        {!hideBanner && <Header />}
        <MCommercesucess />
        <MobileApppExperiencss />
        <Faq />
        <Contact />
        <Footer />
      </div>
    );
  }
}
